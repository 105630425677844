import { createSelector } from '@reduxjs/toolkit'

const messageEntities = (state) => state.messages.entities;

export const getAllMessages = createSelector(messageEntities, (entities) =>
    Object.values(entities)
)

export const getLastReply = (chatId) => createSelector(
    getAllMessages,
    (messages) => {
        const filteredMessages = messages.filter(m => m.chatId === chatId && m.from.type === 'user').sort((a, b) => new Date(b.createdOn) - new Date(a.createdOn));

        if (filteredMessages.length > 0) {
            return filteredMessages[0];
        }
        else {
            return null;
        }
    }
);

export const getMessageById = (state, messageId) => {
    return messageEntities(state)[messageId];
}

export const getMessagesByChatId = (chatId) => createSelector(
    getAllMessages,
    (messages) => {
        return messages.filter(m => m.chatId === chatId).sort((a, b) => new Date(a.createdOn) - new Date(b.createdOn));
    }
);

export const getStatusByChat = (state, chatId) => {
    return state.messages.statusByChat[chatId];
}