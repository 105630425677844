import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useParams, useSearchParams } from "react-router-dom";
import { getActiveTenant, getStatusByTenant, getTenantById } from "../../modules/tenant/selectors";
import { loadTenant, tenantSelected } from "../../modules/tenant/slice";

import businessNotFound from '../../assets/images/shop-256.png';
import { setCssBrandingVariables } from "../../modules/tenant/extensions";

export default function TenantBoundary() {
    const params = useParams();
    const [searchParams] = useSearchParams();

    const origin = window.location.ancestorOrigins && window.location.ancestorOrigins.length > 0 ? window.location.ancestorOrigins[0] : searchParams.get('origin');

    const activeTenant = useSelector(getActiveTenant);
    const loadState = useSelector(state => getStatusByTenant(state, params.tenantId));
    const tenant = useSelector(state => getTenantById(state, params.tenantId));

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(loadTenant({ tenantId: params.tenantId, origin }));
    }, [dispatch, params.tenantId, origin]);

    useEffect(() => {
        if (tenant && (!activeTenant || activeTenant.id !== tenant.id)) {
            dispatch(tenantSelected(tenant));
        }
    }, [activeTenant, dispatch, tenant]);

    useEffect(() => {
        if (tenant) {
            setCssBrandingVariables(tenant.branding);
        }
    }, [tenant]);
    
    if (activeTenant && tenant && activeTenant.id === tenant.id) {
        const icon = tenant.branding.icon ? `data:image/png;base64,${tenant.branding.icon.data}` : '/images/favicon-32x32.png';

        return (
            <React.Fragment>
                <Outlet />

                <Helmet>
                    <title>{tenant.name}</title>
                    {icon && 
                        <link rel="icon" href={icon} type="image/png" />
                    }
                </Helmet>
            </React.Fragment>
        );
    }
    else if (loadState && loadState.status === 'loading') {
        return (
            <div className="bg-default flex-container-vert center">
                <div className="text-center">Loading...</div>
            </div>
        );
    }
    else {
        return (
            <div className="bg-default flex-container-vert center">
                <div className="text-center">
                    <img alt="Business not found" className="img-96 mb-12" src={businessNotFound} />
                    <div>Business account could not be loaded</div>
                </div>
            </div>
        );
    }
}