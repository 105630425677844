import { forwardRef, useImperativeHandle, useRef } from "react";

const FileSelector = forwardRef((props, ref) => {
    const inputRef = useRef(null);

    useImperativeHandle(ref, () => ({
        open() {
            inputRef.current.click();
        }
    }));

    function checkFileCount(files) {
        return !props.maxFiles || files.length <= props.maxFiles;
    }

    function checkFileSize(files) {
        if (props.maxFileSize) {
            for (let i = 0; i < files.length; i++) {
                if (files[i].size > props.maxFileSize) {
                    return false;
                }
            }

            return true;
        }
        else {
            return true;
        }
    }

    function handleFileInputChange(ev) {
        const files = ev.target.files;

        if (files.length > 0) {
            if (!checkFileCount(files)) {
                //const msg = `You can only select up to ${props.maxFiles} files.`;

                //this.alertRef.current.show('File Count', msg);
            }
            else if (!checkFileSize(files)) {
                //const msg = `You can only select files up to ${props.maxFileSize / 1000000} MB.`;

                //this.alertRef.current.show('File Size', msg);
            }
            else {
                props.onFilesSelected(files);
            }

            inputRef.current.value = '';
        }
        else {
            props.onFilesSelected(files);
        }
    }

    return (
        <input type="file" ref={inputRef} onChange={handleFileInputChange} style={{ display: 'none' }} />
    );
});

export default FileSelector;