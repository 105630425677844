import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { IconButton, Spinner, SpinnerSize, TextField } from "@fluentui/react";
import { KeyCodes } from "@fluentui/react";

import { getChatById } from "../../../modules/chat/selectors";
import { createChat, userIsWriting } from "../../../modules/chat/slice";
import { uploadFile } from "../../../modules/file/slice";
import { createMessageAttachment } from "../../../modules/message/extensions";
import { createMessage } from "../../../modules/message/slice";
import { createId, debounce } from "../../../services/Utils";

import { FileSelector } from "../../file";
import { EmojiPicker } from "../../core";

import './MessageComposer.css';

export default function MessageComposer(props) {
    const { readOnly, tenant } = props;

    const [body, setBody] = useState('');
    const [emojiPickerHidden, setEmojiPickerHidden] = useState(true);
    const [uploadingFile, setUploadingFile] = useState(false);

    const chat = useSelector(state => getChatById(state, props.chatId));

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const refEmojiPickerButton = useRef(null);
    const refFileSelector = useRef(null);
    const refTextField = useRef(null);

    const addFiles = async (files) => {
        if (files && files.length > 0) {
            const file = files[0];
            const fileId = createId();

            setUploadingFile(true);

            dispatch(uploadFile({ file, fileId })).unwrap().then(async () => {
                const attachment = await createMessageAttachment(file, fileId);

                sendMessage('', [attachment]);

                setUploadingFile(false);
            });
        }
    }

    const selectEmoji = (emoji) => {
        setEmojiPickerHidden(true);

        if (body === '') {
            sendMessage(`<div class="emoji emoji-lg">${emoji.native}</div>`);
        }
        else {
            setBody(body => body = body.trim() + ` ${emoji.native}`);
        }

        refTextField.current.focus();
    }

    const sendMessage = (body, attachments = []) => {
        if (readOnly) {
            return;
        }

        if (body.trim().length > 0 || attachments.length > 0) {
            if (chat) {
                const request = {
                    chatId: props.chatId,
                    attachments,
                    body: { content: body, contentType: 'html' }
                };

                dispatch(createMessage(request));
            }
            else {
                const request = {
                    id: props.chatId,
                    attachments: attachments,
                    body: { content: body, contentType: 'html' },
                    messageId: createId(),
                    recipient: props.recipient,
                    token: props.token
                };

                dispatch(createChat(request));

                navigate(`/${tenant.id}/conversations/${props.chatId}`);
            }

            setBody('');
            refTextField.current.focus();
        }
    };

    const showEmojiPicker = (ev) => {
        ev.stopPropagation();

        if (!uploadingFile && !readOnly) {
            setEmojiPickerHidden(false);
        }
    }

    const showFileSelector = () => {
        if (!uploadingFile && !readOnly) {
            refFileSelector.current.open();
        }
    }

    const sendUserIsWriting = debounce(() => {
        dispatch(userIsWriting({ chatId: props.chatId }));
    }, 200);

    const onChangeBody = (ev, value) => {
        setBody(value);

        sendUserIsWriting();
    };

    const onKeyDown = (ev) => {
        if (ev.keyCode === KeyCodes.enter) {
            ev.preventDefault();
            ev.stopPropagation();

            sendMessage(body);
        }
    };

    const onSubmit = () => {
        if (!uploadingFile) {
            sendMessage(body);
        }
    }

    const placeholder = uploadingFile ? 'Uploading file...' : 'Send a message...';

    return (
        <div className="message-composer">
            <div className="flex-container-horz">
                {uploadingFile &&
                    <div className="mr-12">
                        <Spinner size={SpinnerSize.medium} />
                    </div>
                }

                <div className="flex-grow">
                    <div className="input-container">
                        <TextField
                            autoAdjustHeight
                            autoComplete="off"
                            autoFocus
                            borderless
                            className="input"
                            componentRef={refTextField}
                            maxLength={500}
                            multiline
                            onChange={onChangeBody}
                            onKeyDown={onKeyDown}
                            placeholder={placeholder}
                            readOnly={uploadingFile || readOnly}
                            resizable={false}
                            rows={1}
                            value={body}
                        />
                    </div>
                </div>
                <div className="flex-container-horz">
                    <div ref={refEmojiPickerButton}>
                        <IconButton iconProps={{ iconName: 'Emoji' }} onClick={showEmojiPicker} title="Insert emoji" />
                    </div>
                    {body.length === 0 &&
                        <IconButton iconProps={{ iconName: 'Attach' }} title="Add attachment" onClick={showFileSelector} />
                    }
                    {body.length > 0 &&
                        <IconButton iconProps={{ iconName: 'Send' }} onClick={onSubmit} title="Send message" />
                    }
                </div>
            </div >

            <EmojiPicker
                hidden={emojiPickerHidden}
                onDismiss={() => setEmojiPickerHidden(true)}
                onSelectEmoji={selectEmoji}
                target={refEmojiPickerButton}
            />

            <FileSelector ref={refFileSelector} onFilesSelected={addFiles} />
        </div>
    );
}