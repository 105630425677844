import FileService from "../../services/FileService";
import { appContext } from '../../AppContext';
import FileAttachment from "./FileAttachment";
import ImageAttachment from "./ImageAttachment";

export default function Attachment(props) {
    const { attachment } = props;

    const downloadAttachment = () => {
        const fileService = new FileService(appContext());
        fileService.createFileToken(attachment.fileId).then(token => {
            const url = `${process.env.REACT_APP_API_URL}/api/messenger/files/${token}/download`;

            const iframeId = 'download-frame';
            let iframe = document.getElementById(iframeId);

            if (!iframe) {
                iframe = document.createElement('iframe');
                iframe.id = iframeId;
                iframe.style = 'display: none;';

                document.body.appendChild(iframe);
            }

            iframe.src = url;
        });
    }

    if (attachment.contentDimension && attachment.contentType && attachment.contentType.indexOf('image/') === 0) {
        return (
            <ImageAttachment attachment={attachment} onDownload={downloadAttachment} />
        );
    }
    else {
        return (
            <FileAttachment attachment={attachment} onDownload={downloadAttachment} />
        );
    }
}