class HostService {
    constructor(appContext) {
        this.appContext = appContext;

        this.notifyChatSubscription = this.notifyChatSubscription.bind(this);
        this.notifyDismiss = this.notifyDismiss.bind(this);
        this.notifyHandOff = this.notifyHandOff.bind(this);
        this.notifyInboundMessage = this.notifyInboundMessage.bind(this);
        this.notifySignOut = this.notifySignOut.bind(this);
    }

    notifyChatSubscription(chatId) {
        const ev = { type: 'CHAT_SUBSCRIPTION', data: { chatId } };

        this.appContext.host.postEvent(ev);
    }

    notifyDismiss() {
        const ev = { type: 'CHAT_DISMISS', data: {} };

        this.appContext.host.postEvent(ev);
    }

    notifyHandOff() {
        const ev = { type: 'CHAT_HAND_OFF', data: {} };

        this.appContext.host.postEvent(ev);
    }

    notifyInboundMessage() {
        const ev = { type: 'INBOUND_MESSAGE', data: {} };

        this.appContext.host.postEvent(ev);
    }

    notifySignOut() {
        const ev = { type: 'SIGN_OUT', data: {} };

        this.appContext.host.postEvent(ev);
    }
}

export default HostService;