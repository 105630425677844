import { DateTime } from "luxon";
import { getFirstName, getLastName } from "../../services/Utils";

export function createChat(request, user) {
    return {
        id: request.id,
        createdOn: DateTime.utc().toISO(),
        members: [{ identity: user }],
        channel: request.recipient
    };
}

export function resolveVariables(text, ctx) {
    let result = text;

    result = result.replace('{{first_name}}', getFirstName(ctx.user.name));
    result = result.replace('{{last_name}}', getLastName(ctx.user.name));

    return result;
}