import ApiService from "./ApiService";

class MessageService {
    constructor(appContext) {
        this.createMessage = this.createMessage.bind(this);
        this.loadMessage = this.loadMessage.bind(this);
        this.loadMessagesByChatId = this.loadMessagesByChatId.bind(this);
        this.markMessageAsRead = this.markMessageAsRead.bind(this);

        this.api = new ApiService(appContext);
    }

    async createMessage(request) {
        const { chatId, ...data } = request;

        const response = await this.api.post(`/api/messenger/chats/${chatId}/messages`, data);

        return response.data;
    }

    async loadMessage(messageId) {
        const response = await this.api.get(`/api/messenger/messages/${messageId}`);

        return response.data;
    }

    async loadMessagesByChatId(chatId, skip = null) {
        let url = `/api/messenger/chats/${chatId}/messages`;

        if (skip) {
            url += `?skip=${skip}`;
        }

        const response = await this.api.get(url);

        return response.data;
    }

    async markMessageAsRead(messageId) {
        await this.api.post(`/api/messenger/messages/${messageId}/read`);
    }
}

export default MessageService;