export default function ReplyTime({ profile, replyTime }) {
    const prefix = profile.$type === 'userProfile' ? 'I' : 'We';

    switch (replyTime) {
        case 'minutes':
            return `${prefix} typically reply in a few minutes`;

        case 'hours':
            return `${prefix} typically reply in a few hours`;

        default:
        case 'day':
            return `${prefix} typically reply within a day`;
    }
}