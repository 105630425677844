export default function FileSizeLabel(props) {
    const { bytes } = props;

    let size = bytes / 1024;
    let sizeUnit = 'KB';

    if (size > 1024) {
        size = size / 1024;
        sizeUnit = 'MB';
    }

    return `${Math.round(size)} ${sizeUnit}`;
}