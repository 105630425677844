import { createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { appContext } from "../../AppContext";
import IdentityService from "../../services/IdentityService";
import { subscribeToPresence } from "../presence/slice";

// adapter
const identitiesAdapter = createEntityAdapter();

// initial state
const initialState = identitiesAdapter.getInitialState({
    identityByToken: {}
});

// thunks
export const addIdentity = createAsyncThunk('identities/addIdentity', (identity, thunkAPI) => {
    if (identity.members && identity.members.length > 0) {
        thunkAPI.dispatch(addIdentities(identity.members));
    }
    else {
        thunkAPI.dispatch(subscribeToPresence({ identityIds: [ identity.id ] }));
    }

    return identity;
});

export const addIdentities = createAsyncThunk('identities/addIdentities', (request, thunkAPI) => {
    const identityIds = [];
    
    request.forEach(identity => {
        if (identity.members && identity.members.length > 0) {
            thunkAPI.dispatch(addIdentities(identity.members));
        }
        else {
            identityIds.push(identity.id);
        }
    });

    thunkAPI.dispatch(subscribeToPresence({ identityIds }));

    return request;
});

export const loadIdentityByToken = createAsyncThunk('identities/loadIdentityByToken', async (request, thunkAPI) => {
    const identityService = new IdentityService(appContext());
    const identity = await identityService.loadIdentityByToken(request);

    if (identity) {
        if (identity.members && identity.members.length > 0) {
            thunkAPI.dispatch(addIdentities(identity.members));
        }
        else {
            thunkAPI.dispatch(subscribeToPresence({ identityIds: [ identity.id ] }));
        }
    }

    return identity;
});

// slice
const slice = createSlice({
    name: 'identities',
    initialState,
    extraReducers(builder) {
        builder
            .addCase(addIdentity.fulfilled, (state, action) => {
                identitiesAdapter.upsertOne(state, action.payload);
            })
            .addCase(addIdentities.fulfilled, (state, action) => {
                identitiesAdapter.upsertMany(state, action.payload);
            })
            .addCase(loadIdentityByToken.pending, (state, action) => {
                state.identityByToken[action.meta.arg.token] = { status: 'loading' };
            })
            .addCase(loadIdentityByToken.fulfilled, (state, action) => {
                if (action.payload) {
                    identitiesAdapter.upsertOne(state, action.payload);
                    state.identityByToken[action.meta.arg.token] = { identityId: action.payload.id, status: 'idle' };
                }
                else {
                    state.identityByToken[action.meta.arg.token] = { status: 'idle' };
                }
            });
    }
});

// reducer
export default slice.reducer;