import { createSelector } from "@reduxjs/toolkit";
import { getActiveTenant } from "../tenant/selectors";

const sessionEntities = (state) => state.sessions.entities;

export const getAllSessions = createSelector(sessionEntities, (entities) =>
    Object.values(entities)
)

export const getSession = createSelector(
    getAllSessions,
    getActiveTenant,
    (sessions, tenant) => {
        if (tenant) {
            return sessions.find(s => s.id === tenant.id);
        }
        else {
            return null;
        }
    }
);