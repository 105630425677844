import { DateTime } from "luxon";

import { createId } from "../../services/Utils";
import { getContentDimension } from "../file/extensions";

export function createMessage(request) {
    return {
        id: createId(),
        createdOn: DateTime.utc().toISO(),
        attachments: [],
        reactions: [],
        status: 'processing',
        ...request
    };
}

export async function createMessageAttachment(file, fileId) {
    const contentDimension = file.type.indexOf('image/') === 0 ? await getContentDimension(file) : null;

    return {
        id: createId(),
        contentDimension: contentDimension,
        contentType: file.type,
        name: file.name,
        fileId: fileId,
        size: file.size
    };
}

export function groupMessagesBySender(messages) {
    const result = [];
    
    let group = null;

    messages.forEach((message, index) => {
        const addToGroup = group && group.sender.id === message.from.id;

        if (addToGroup) {
            group.items.push({
                index: index,
                message: message
            });
        }
        else {
            group = {
                id: `group_${index}`,
                items: [{ index, message }],
                sender: message.from
            };

            result.push(group);
        }
    });

    return result;
}   