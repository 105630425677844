import * as signalR from '@aspnet/signalr';
import { loadMessage } from '../modules/message/slice';
import { presenceLoaded } from '../modules/presence/slice';
import { addEvent } from '../modules/subscription/slice';

class ClientConnectionService {
    constructor(appContext) {
        this.appContext = appContext;

        this.onMessageReceived = this.onMessageReceived.bind(this);
        this.sendEvent = this.sendEvent.bind(this);
        this.subscribe = this.subscribe.bind(this);
        this.unsubscribe = this.unsubscribe.bind(this);
    }

    onMessageReceived(type, data) {
        switch (type) {
            case 'EventSent':
                this.appContext.dispatch(addEvent(data));
                break;

            case 'MessageUpdated':
                this.appContext.dispatch(loadMessage(data));
                break;

            case 'PresenceUpdated':
                this.appContext.dispatch(presenceLoaded(data));
                break;

            default:
                break;
        }
    }

    sendEvent(ev) {
        this.appContext.invokeClientConnection('sendEvent', ev);
    }

    subscribe(request) {
        this.appContext.invokeClientConnection('subscribe', request);

        return convertRequestToSubscriptions(request);
    }

    unsubscribe(request) {
        this.appContext.invokeClientConnection('unsubscribe', request);

        return convertRequestToSubscriptions(request);
    }
}

export function createClientConnection({ onConnected, onDisconnected, onMessageReceived, token }) {
    const protocol = new signalR.JsonHubProtocol();
    const transport = signalR.HttpTransportType.WebSockets;

    const options = {
        accessTokenFactory: () => token,
        transport,
        withCredentials: true
    };

    // create the connection instance
    const connection = new signalR.HubConnectionBuilder()
        .withUrl(`${process.env.REACT_APP_API_URL}/broadcast`, options)
        .withHubProtocol(protocol)
        .build();

    connection.on("ReceiveMessage", onMessageReceived);

    connection.onclose(() => {
        startClientConnection(connection, onConnected, onDisconnected);
    });

    startClientConnection(connection, onConnected, onDisconnected);

    return connection;
}

export function startClientConnection(connection, onConnected, onDisconnected) {
    connection.start().then(() => {
        onConnected(connection);
    }).catch(err => {
        setTimeout(() => startClientConnection(connection, onConnected, onDisconnected), 5000);

        onDisconnected(err);
    });
}

function convertRequestToSubscriptions(request) {
    return request.resources.map(r => `${request.type}-${r}`);
}

export default ClientConnectionService;