export function getLanguage() {
    return navigator.language;
}

export function getTimeZone() {
    try {
        return Intl.DateTimeFormat().resolvedOptions().timeZone;
    }
    catch (ex) { 
        return null;
    }
}

export function getUrlParameter(url, name) {
    // eslint-disable-next-line
    name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
    var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
    var results = regex.exec(url);
    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
};