import { useSelector } from "react-redux";
import { getEventsByType } from "../../../modules/subscription/selectors";
import { Avatar } from "../../avatar";

import loadingImage from '../../../assets/icons/333/dots-loading.gif';

import './TypeIndicator.css';

export default function TypeIndicator(props) {
    const events = useSelector(state => getEventsByType(state, props.chatId, 'userIsWriting'));

    if (events && events.length > 0) {
        const { principal } = events[0];

        return (
            <div className="type-indicator">
                <Avatar identityId={principal.id} name={principal.name} />
                <div className="body">
                    <img alt="Writing" src={loadingImage} />
                </div>
            </div>
        );
    }
    else {
        return null;
    }
}