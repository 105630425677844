import { getIdentityById } from "../identity/selectors";

const presenceEntities = (state) => state.presence.entities;

export const getPresenceById = (state, identityId) => {
    const presence = presenceEntities(state)[identityId];

    if (presence) {
        return presence;
    }
    else {
        const identity = getIdentityById(state, identityId);

        if (identity && identity.members && identity.members.length > 0) {
            const membersPresence = identity.members.map(m => presenceEntities(state)[m.id]).filter(p => p);

            if (membersPresence.length === 0) {
                return null;
            }
            else {
                const onlineMembers = membersPresence.filter(p => p && p.status === 'available');

                if (onlineMembers.length > 0) {
                    return { id: identityId, status: 'available' };
                }
                else {
                    return { id: identityId, status: 'offline' };
                }
            }
        }

        return null;
    }
}