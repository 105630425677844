import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useParams } from "react-router-dom";
import { getSession } from "../../modules/session/selectors";
import { startSession } from "../../modules/session/slice";

export default function SessionBoundary() {
    const dispatch = useDispatch();
    const params = useParams();

    const session = useSelector(getSession);

    useEffect(() => {
        if (!session) {
            dispatch(startSession());
        }
    }, [dispatch, params.tenantId, session]);

    return (
        <Outlet />
    );
}