export default function JobTitle(props) {
    const { jobTitle, organization } = props.profile;

    if (jobTitle || organization) {
        let title = jobTitle ? jobTitle : '';

        if (organization) {
            if (jobTitle) {
                title += ' at ';
            }
            title += ` ${organization}`;
        }

        return (
            <div className="job-title">{title}</div>
        );
    }
    else {
        return null;
    }
}