import { createSelector } from '@reduxjs/toolkit'
import { getSession } from '../session/selectors';
import { getActiveTenant } from '../tenant/selectors';

const userEntities = (state) => state.users.entities;

const getAllUsers = createSelector(userEntities, (entities) =>
    Object.values(entities)
)

export const getUsers = createSelector(
    getAllUsers,
    getActiveTenant,
    (users, tenant) => {
        return users.filter(u => u.tenant === tenant.id);
    }
);

export const getUser = createSelector(
    getUsers,
    getSession,
    (users, session) => {
        if (users.length > 0 && session && session.userToken) {
            const user = users.find(u => u.tokens.indexOf(session.userToken) > -1);

            return user ? user : users.find(u => u.id === session.userId);
        }
        else {
            return null;
        }
    }
);