import { useParams } from "react-router-dom";
import { ChatSession } from "../components/chat";

export default function ConversationsPage(props) {
    const params = useParams();

    return (
        <ChatSession
            chatId={params.chatId}
            token={params.token}
        />
    );
}