import { useSelector } from "react-redux";
import { Navigate, Outlet, useLocation } from "react-router-dom";

import { getActiveTenant } from "../../modules/tenant/selectors";
import { getUser } from "../../modules/user/selectors";

export default function UserBoundary(props) {
    const location = useLocation();
    const tenant = useSelector(getActiveTenant);
    const user = useSelector(getUser);

    if (user) {
        return (
            <Outlet />
        )
    }
    else if (tenant) {
        const redirectUrl = location.pathname + location.search;
        let to = `/${tenant.id}/unauthorized?return_url=${encodeURIComponent(redirectUrl)}`;

        return (
            <Navigate to={to} />
        );
    }
    else {
        return (
            <Navigate to="/" />
        );
    }
}