import { ProfileSidebar } from "../../identity";

export default function ConversationProfile(props) {
    const { chat, identity, tenant } = props;

    let selectedIdentity = null;

    if (chat) {
        selectedIdentity = chat.channel;
    }
    else {
        selectedIdentity = identity;
    }

    if (selectedIdentity) {
        return (
            <ProfileSidebar identity={selectedIdentity} tenant={tenant} />
        );
    }
    else {
        return null;
    }
}