import PropTypes from "prop-types";
import { DateTime } from "luxon";
import { useEffect, useState } from "react";

function DateTimeLabel(props) {
    const autoRefresh = typeof props.value === 'function' || props.toRelative;
    
    const [now, setNow] = useState(DateTime.now());

    useEffect(() => {
        if (autoRefresh) {
            const id = setInterval(() => {
                setNow(DateTime.now());
            }, 5000);

            return () => clearInterval(id);
        }
    }, [now, autoRefresh]);

    const getValue = () => {
        const resolvedValue = typeof props.value === 'function' ? props.value() : props.value;

        if (typeof resolvedValue === 'string') {
            if (props.fromFormat) {
                return DateTime.fromFormat(resolvedValue, props.fromFormat, { zone: props.timeZone }).toLocal();
            }
            else {
                return DateTime.fromISO(resolvedValue);
            }
        }
        else {
            return resolvedValue;
        }
    }

    const renderToFormat = (format) => {
        const value = getValue();

        return value.toFormat(format);
    }

    const renderToRelative = () => {
        const value = getValue();
        const diff = now.diff(value, 'minutes');

        if (diff.minutes < 1) {
            return 'Now';
        }
        else {
            return value.toRelative({ unit: ['days', 'hours', 'minutes'] });
        }
    }

    if (props.toFormat) {
        return renderToFormat(props.toFormat);
    }
    else if (props.toRelative) {
        return renderToRelative();
    }
    else {
        return null;
    }
}

DateTimeLabel.propTypes = {
    fromFormat: PropTypes.string,
    timeZone: PropTypes.string,
    toFormat: PropTypes.string,
    toRelative: PropTypes.any,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired
};

DateTimeLabel.defaultProps = {
    timeZone: 'UTC'
};

export default DateTimeLabel;