import Attachment from "./Attachment";

import './AttachmentsList.css';

export default function AttachmentsList(props) {
    const renderedAttachments = props.attachments.map(attachment => {
        return (
            <Attachment key={attachment.id} attachment={attachment} />
        );
    });

    return (
        <div className="attachments-list">
            {renderedAttachments}
        </div>
    );
}