import logo from '../../assets/images/logo_yablo_gs_256@2x.png';

import './ProductLink.css';

export default function ProductLink() {
    const url = 'https://yablo.io/?utm_source=messenger&utm_medium=messenger';

    return (
        <div className="flex-container-horz center mt-12">
            <a className="product-link" href={url} target="_blank" rel="noreferrer">
                <img alt="Yablo logo" className="logo" src={logo} /> Powered by Yablo
            </a>
        </div>
    );
}