import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import { appContext } from '../../AppContext';
import TenantService from '../../services/TenantService';

// adapter
const tenantsAdapter = createEntityAdapter();

// initial state
const initialState = tenantsAdapter.getInitialState({
    selectedTenantId: null,
    statusByTenant: {}
});

// thunks
export const loadTenant = createAsyncThunk('tenants/loadTenant', async (request) => {
    const service = new TenantService(appContext());
    return await service.loadTenant(request.tenantId, request.origin);
});

// slice
const slice = createSlice({
    name: 'tenants',
    initialState,
    reducers: {
        tenantLoaded(state, action) {
            if (action.payload) {
                tenantsAdapter.upsertOne(state, action.payload);

                state.selectedTenantId = action.payload.id;
            }
            else {
                state.selectedTenantId = null;
            }
        },
        tenantSelected(state, action) {
            if (action.payload) {
                state.selectedTenantId = action.payload.id;
            }
            else {
                state.selectedTenantId = null;
            }
        }
    },
    extraReducers(builder) {
        builder
            .addCase(loadTenant.pending, (state, action) => {
                state.statusByTenant[action.meta.arg.tenantId] = { status: 'loading' };
            })
            .addCase(loadTenant.fulfilled, (state, action) => {
                slice.caseReducers.tenantLoaded(state, action);
                state.statusByTenant[action.meta.arg.tenantId] = { status: 'idle' };
            })
    }
});

// actions
export const { tenantLoaded, tenantSelected } = slice.actions;

// reducer
export default slice.reducer;