import { AnimationStyles } from '@fluentui/react';
import DOMPurify from 'dompurify';
import { MessageAttachments } from '../../chat';

import './Message.css';

export default function Message(props) {
    const { message } = props;

    const sanitizedHtml = DOMPurify.sanitize(message.body.content, { ADD_ATTR: ['target'] });

    return (
        <div className="message" style={AnimationStyles.slideUpIn20}>
            <div className="message-bubble">
                {message.attachments && message.attachments.length > 0 && 
                    <MessageAttachments attachments={message.attachments} />
                }

                {message.body && message.body.content.length > 0 && 
                    <div className="body" dangerouslySetInnerHTML={{ __html: sanitizedHtml }}></div>
                }
            </div>
        </div>
    );
}