import { getTertiaryBackgroundColor } from "../../modules/tenant/extensions";
import Profile from "./Profile";

export default function ProfileSidebar(props) {
    const { tenant } = props;

    const backgroundColor = tenant ? getTertiaryBackgroundColor(tenant.branding) : null;

    const style = { backgroundColor };

    return (
        <div className="profile-sidebar hidden-sm" style={style}>
            <Profile {...props} />
        </div>
    );
}