import { useSelector } from "react-redux";
import { ChatPreview } from "../components/chat";

export default function PreviewPage() {
    const preview = useSelector(state => state.host.preview);

    if (preview) {
        const { identity, tenant, user } = preview;

        if (identity && tenant && user) {
            return (
                <div className="root-layout">
                    <div className="modal-layout-container">
                        <div className="modal-layout">
                            <ChatPreview identity={identity} tenant={tenant} user={user} />
                        </div>
                    </div>
                </div>
            );
        }
    }

    return null;
}