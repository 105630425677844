import ApiService from "./ApiService";

class FileService {
    constructor(appContext) {
        this.createFileToken = this.createFileToken.bind(this);
        this.loadFilePreview = this.loadFilePreview.bind(this);
        this.uploadFile = this.uploadFile.bind(this);
        
        this.api = new ApiService(appContext);
    }

    async createFileToken(fileId) {
        const response = await this.api.post(`/api/messenger/files/${fileId}/token`);

        return response.data;
    }

    async loadFilePreview(request) {
        const { fileId } = request;

        const response = await this.api.get(`/api/messenger/files/${fileId}/preview`, { responseType: 'blob' });

        return response.data;
    }

    async uploadFile(request, file) {
        const response = await this.api.postFile('/api/messenger/files', file, request);

        return response.data;
    }
}

export default FileService;