import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { appContext } from "../../AppContext";
import HostService from "../../services/HostService";
import { openChatInNewWindow } from "./extensions";
import { setCssBrandingVariables } from "../tenant/extensions";

// initial state
const initialState = {
    preview: null,
    visibility: 'visible'
}

// thunks
export const dismissChat = createAsyncThunk('host/dismiss', () => {
    const hostService = new HostService(appContext());
    hostService.notifyDismiss();
});

export const handOffChat = createAsyncThunk('host/handOff', (request) => {
    openChatInNewWindow(request.url);

    const hostService = new HostService(appContext());
    hostService.notifyHandOff();
});

// slice
const slice = createSlice({
    name: 'host',
    initialState,
    reducers: {
        previewChanged(state, action) {
            state.preview = action.payload;

            if (action.payload) {
                setCssBrandingVariables(action.payload.tenant.branding);
            }
        },
        visibilityChanged(state, action) {
            state.visibility = action.payload;
        }
    }
});

// actions
export const { previewChanged, visibilityChanged } = slice.actions;

// reducer
export default slice.reducer;