import Picker from '@emoji-mart/react'
import { Callout, DirectionalHint } from '@fluentui/react';

import "./EmojiPicker.css";

export default function EmojiPicker(props) {
    const { hidden, onDismiss, onSelectEmoji, target } = props;

    const dismiss = () => {
        if (!hidden) {
            onDismiss();
        }
    }

    const loadEmoji = async () => {
        const response = await fetch('https://cdn.jsdelivr.net/npm/@emoji-mart/data');

        return response.json()
    }

    return (
        <Callout 
            className="emoji-picker"
            directionalHint={DirectionalHint.topAutoEdge}
            hidden={hidden}
            gapSpace={6}
            isBeakVisible={false}
            target={target}
        >
            <Picker
                data={loadEmoji}
                emojiButtonRadius="8px"
                emojiButtonSize={38}
                onClickOutside={dismiss}
                onEmojiSelect={onSelectEmoji}
                navPosition="bottom"
                perLine={8}
                previewPosition="none"
                theme="dark"
            />
        </Callout>
    );
}