import { PrimaryButton, Spinner, SpinnerSize, TextField } from "@fluentui/react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getActiveTenant } from "../../../modules/tenant/selectors";
import { connectUser } from "../../../modules/user/slice";

import { validateEmailAddress } from "../../../services/validators";

import wave from '../../../assets/images/wave.png';
import './UserForm.css';


const WELCOME_USER = "It is always pleasant to know who I'm talking to. Enter your contact details and hop on a chat with me 🙏.";
const WELCOME_TEAM = "It is always pleasant to know who we're talking to. Enter your contact details and hop on a chat with us 🙏.";

const EMAIL_DESC_USER = "I only use your email address to follow up with you in case I can't respond right away.";
const EMAIL_DESC_TEAM = "We only use your email address to follow up with you in case we can't respond right away.";

export default function UserForm(props) {
    const { identity } = props;

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');

    const dispatch = useDispatch();
    const loadingStatus = useSelector(state => state.users.status);
    const tenant = useSelector(getActiveTenant);

    const getErrorMessage = (value) => {
        return value.trim() === '' || validateEmailAddress(value) ? '' : 'Not a valid email address';
    }

    const onChangeName = (ev, value) => {
        setName(value);
    };

    const onChangeEmail = (ev, value) => {
        setEmail(value);
    };

    const onConnectUser = () => {
        dispatch(connectUser({
            name,
            email,
            identity,
            tenant: tenant.id
        }));
    };

    const disabled = name.length === 0 || email.length === 0 || !validateEmailAddress(email);
    const type = identity ? identity.type : 'shared';

    if (loadingStatus === 'loading') {
        return (
            <div className="user-form">
                <Spinner size={SpinnerSize.medium} label="Getting connected..." />
            </div>
        );
    }
    else {
        return (
            <div className="user-form">
                <h2>Hi <img alt="👋" src={wave} style={{ width: '1em', height: '1em', margin: '0px 0.05em 0px 0.1em', verticalAlign: '-0.1em' }} /></h2>
                <p>{type === 'user' ? WELCOME_USER : WELCOME_TEAM}</p>
                <div className="section section-sm">
                    <TextField
                        autoCapitalize="words"
                        autoCorrect="off"
                        autoFocus
                        label="What is your name?"
                        name="name"
                        onChange={onChangeName}
                        placeholder="Enter your name"
                        type="email"
                        value={name}
                    />
                </div>
                <div className="section section-sm">
                    <TextField
                        autoCorrect="off"
                        description={type === 'user' ? EMAIL_DESC_USER : EMAIL_DESC_TEAM}
                        label="What is your email address?"
                        name="email"
                        onChange={onChangeEmail}
                        onGetErrorMessage={getErrorMessage}
                        deferredValidationTime={900}
                        placeholder="example@email.com"
                        value={email}
                    />
                </div>
                <div className="section text-center">
                    <PrimaryButton disabled={disabled}  onClick={onConnectUser}>Start a new chat</PrimaryButton>
                </div>
            </div>
        );
    }
}