import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import { appContext } from '../../AppContext';
import UserService from '../../services/UserService';
import { getLanguage, getTimeZone } from '../../services/web/utils';
import { subscribeToPresence } from '../presence/slice';
import { createSession } from '../session/extensions';
import { sessionStarted } from '../session/slice';
import { createUser } from './extensions';

// adapter
const usersAdapter = createEntityAdapter();

// initial state
const initialState = usersAdapter.getInitialState({
    status: 'initial'
});

// thunks
export const connectUser = createAsyncThunk('users/connect', async (request, thunkAPI) => {
    const language = getLanguage();
    const timeZone = getTimeZone();

    const userService = new UserService(appContext());
    const user = await userService.connectUser({ ...request, language, timeZone });

    thunkAPI.dispatch(sessionStarted(createSession(user)));

    thunkAPI.dispatch(subscribeToPresence({ identityIds: [ request.identity.id ] }));

    return user;
});

export const joinUser = createAsyncThunk('users/join', async (request, thunkAPI) => {
    const language = getLanguage();
    const timeZone = getTimeZone();

    const userService = new UserService(appContext());
    const user = await userService.joinUser({ ...request, language, timeZone });

    thunkAPI.dispatch(sessionStarted(createSession(user)));

    return user;
});

// slice
const slice = createSlice({
    name: 'users',
    initialState,
    reducers: {
        userConnected(state, action) {
            const user = usersAdapter.getSelectors().selectById(state, action.payload.id)

            if (user) {
                if (user.tokens.indexOf(action.payload.token) === -1) {
                    const update = { id: user.id, tokens: [...user.tokens, action.payload.token] };
                    usersAdapter.updateOne(state, update);
                }
            }
            else {
                usersAdapter.addOne(state, createUser(action.payload));
            }
        }
    },
    extraReducers(builder) {
        builder
            .addCase(connectUser.pending, (state, action) => {
                state.status = 'loading';
            })
            .addCase(connectUser.fulfilled, (state, action) => {
                if (action.payload) {
                    slice.caseReducers.userConnected(state, action);
                }
                state.status = 'idle';
            })
            .addCase(joinUser.pending, (state, action) => {
                state.status = 'loading';
            })
            .addCase(joinUser.fulfilled, (state, action) => {
                if (action.payload) {
                    slice.caseReducers.userConnected(state, action);
                }
                state.status = 'idle';
            });
    }
});

// actions
export const { userConnected } = slice.actions;

// reducer
export default slice.reducer;