import PropTypes from "prop-types";
import { Persona, PersonaPresence, PersonaSize } from "@fluentui/react";
import { useSelector } from "react-redux";
import { getIdentityById } from "../../modules/identity/selectors";
import { getPresenceById } from "../../modules/presence/selectors";

function Avatar(props) {
    const { hidePersonaDetails, hidePresence, identityId, name, size } = props;

    const identity = useSelector(state => getIdentityById(state, identityId));
    const presence = useSelector(state => getPresenceById(state, identityId));

    const convertToPersonaPresence = (presence) => {
        if (presence && !hidePresence) {
            switch (presence.status) {
                case 'available':
                    return PersonaPresence.online

                case 'away':
                    return PersonaPresence.away;

                default:
                    return PersonaPresence.offline;
            }
        }
        else {
            return PersonaPresence.none;
        }
    }

    const getAvatar = () => {
        if (props.avatar) {
            return props.avatar;
        }
        else if (identity && identity.profile) {
            return identity.profile.avatar;
        }
        else {
            return null;
        }
    };

    const getImageUrl = () => {
        const avatar = getAvatar();
        
        if (avatar) {
            return `data:image/png;base64,${avatar.data}`;
        }
        else {
            return null;
        }
    };

    const personaPresence = convertToPersonaPresence(presence);
    const imageUrl = getImageUrl();

    return (
        <Persona 
            className="avatar"
            hidePersonaDetails={hidePersonaDetails}
            imageUrl={imageUrl}
            imageShouldStartVisible
            presence={personaPresence}
            size={size} 
            text={name}
            title={name}
        />
    );
}

Avatar.propTypes = {
    avatar: PropTypes.any,
    hidePersonaDetails: PropTypes.bool,
    hidePresence: PropTypes.bool,
    identityId: PropTypes.string,
    name: PropTypes.any.isRequired,
    size: PropTypes.number
}

Avatar.defaultProps = {
    hidePersonaDetails: true,
    size: PersonaSize.size32
}

export default Avatar;