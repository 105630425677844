import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getFileById } from "../../modules/file/selectors";
import { loadFilePreview } from "../../modules/file/slice";

export default function ImageAttachment(props) {
    const previewMaxHeight = 200;
    const previewMaxWidth = 200;

    const { attachment, onDownload } = props;
    const { contentDimension } = attachment;

    const dispatch = useDispatch();

    const file = useSelector(state => getFileById(state, attachment.fileId));

    useEffect(() => {
        if (!file || !file.preview) {
            dispatch(loadFilePreview({ fileId: attachment.fileId }));
        }
    }, [attachment.fileId, dispatch, file]);

    const calculateDimension = () => {
        let height = contentDimension.height;
        let width = contentDimension.width;

        if (width > previewMaxWidth) {
            const ratio = width / previewMaxWidth;

            width = previewMaxWidth;
            height = height / ratio;
        }

        if (height > previewMaxHeight) {
            const ratio = height / previewMaxHeight;

            height = previewMaxHeight;
            width = width / ratio;
        }

        return { width, height };
    };

    const imageUrl = file ? file.preview : null;
    const { width, height } = calculateDimension();

    return (
        <div className="attachment image" onClick={onDownload}>
            {imageUrl && <img alt={attachment.name} className="img-fluid" src={imageUrl} style={{ height: height, width: width }} />}
            {!imageUrl && <div style={{ height: height, width: width }} />}
        </div>
    );
}