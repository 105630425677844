import { getWeekday } from "../../services/Utils";
import { DateTimeLabel } from "../core";

export default function BusinessHours(props) {
    const { businessHours, timeZone } = props;

    const businessHour = businessHours.find(h => h.day === getWeekday());

    if (businessHour) {
        return (
            <div><DateTimeLabel fromFormat="HH:mm" timeZone={timeZone} toFormat="t" value={businessHour.startTime} /> - <DateTimeLabel fromFormat="HH:mm" timeZone={timeZone} toFormat="t" value={businessHour.endTime} /></div>
        );
    }
    else {
        return 'Not online today';
    }
}