export default function Location(props) {
    const { location } = props;

    let text = location.city ? location.city : '';

    if (location.country) {
        if (location.city) {
            text += ', ';
        }

        text += location.country;
    }

    return text;
}