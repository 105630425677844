import { createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { subscribe } from "../subscription/slice";

// adapter
const presenceAdapter = createEntityAdapter();

// initial state
const initialState = presenceAdapter.getInitialState();

// thunks
export const subscribeToPresence = createAsyncThunk('presence/subscribe', async (request, thunkAPI) => {
    thunkAPI.dispatch(subscribe({ type: 'presence', resources: request.identityIds }));
});

// slice
const slice = createSlice({
    name: 'presence',
    initialState,
    reducers: {
        presenceLoaded(state, action) {
            presenceAdapter.upsertOne(state, action.payload);
        }
    }
});

// actions
export const { presenceLoaded } = slice.actions;

// reducer
export default slice.reducer;