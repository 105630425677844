import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import { appContext } from '../../AppContext';
import ChatService from '../../services/ChatService';
import HostService from '../../services/HostService';
import NotificationService from '../../services/NotificationService';
import { addIdentities } from '../identity/slice';
import { createMessage } from '../message/extensions';
import { loadMessages, messageAdded } from '../message/slice';
import { subscribe } from '../subscription/slice';
import { getUser } from '../user/selectors';

import * as extensions from './extensions';

// adapter
const chatsAdapter = createEntityAdapter();

// initial state
const initialState = chatsAdapter.getInitialState({
    statusByChat: {}
});

// thunks
export const createChat = createAsyncThunk('chats/createChat', (request, thunkAPI) => {
    const user = getUser(appContext().getState());

    const chat = extensions.createChat(request, user);
    thunkAPI.dispatch(chatAdded(chat));

    const message = createMessage({ attachments: request.attachments, body: request.body, chatId: chat.id, from: { id: user.id, name: user.name }, id: request.messageId, status: 'delivered' });
    thunkAPI.dispatch(messageAdded(message));

    const notificationService = new NotificationService(appContext());
    notificationService.playSound('outbound');

    const chatService = new ChatService(appContext());
    return chatService.createChat(request);
});

export const loadChat = createAsyncThunk('chats/loadChat', async (request, thunkAPI) => {
    const chatService = new ChatService(appContext());
    const chat = await chatService.loadChat(request.chatId);

    if (chat) {
        const identities = chat.members.map(m => m.identity);
        if (chat.channel) {
            identities.push(chat.channel);
        }

        thunkAPI.dispatch(addIdentities(identities));

        await thunkAPI.dispatch(loadMessages({ chatId: chat.id }));

        await thunkAPI.dispatch(markChatAsRead({ chatId: chat.id }));
    }

    return chat;
});

export const markChatAsRead = createAsyncThunk('chats/markAsRead', async (request) => {
    const chatService = new ChatService(appContext());
    await chatService.markAsRead(request.chatId);
});

export const subscribeToChat = createAsyncThunk('chats/subscribe', async (request, thunkAPI) => {
    thunkAPI.dispatch(subscribe({ type: 'chat', resources: [ request.chatId ] }));

    const hostService = new HostService(appContext());
    hostService.notifyChatSubscription(request.chatId);
});

export const userIsWriting = createAsyncThunk('chats/writing', (request) => {
    const chatService = new ChatService(appContext());
    chatService.sendUserIsWriting(request.chatId);
});

// slice
const slice = createSlice({
    name: 'chats',
    initialState,
    reducers: {
        chatAdded(state, action) {
            chatsAdapter.addOne(state, action.payload);
        }
    },
    extraReducers(builder) {
        builder
            .addCase(loadChat.pending, (state, action) => {
                state.statusByChat[action.meta.arg.chatId] = { status: 'loading' };
            })
            .addCase(loadChat.fulfilled, (state, action) => {
                if (action.payload) {
                    chatsAdapter.addOne(state, action.payload);
                }
                state.statusByChat[action.meta.arg.chatId] = { status: 'idle' };
            });
    }
});

// actions
export const { chatAdded } = slice.actions;

// reducer
export default slice.reducer;