import { usePrevious } from "@fluentui/react-hooks";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { groupMessagesBySender } from "../../../modules/message/extensions";
import { getMessagesByChatId, getStatusByChat } from "../../../modules/message/selectors";
import { loadMessages } from "../../../modules/message/slice";

import { MessageGroup, TypeIndicator, WelcomeMessage } from "../../chat";
import { ClassNameBuilder, ProductLink, ScrollView } from "../../core";

import './MessageContainer.css';

export default function MessageContainer(props) {
    const messages = useSelector(getMessagesByChatId(props.chatId));
    const loadState = useSelector(state => getStatusByChat(state, props.chatId));

    const prevMessages = usePrevious(messages);
    const messageGroups = groupMessagesBySender(messages);

    const [scrolled, setScrolled] = useState(false);

    const dispatch = useDispatch();
    const scrollView = useRef(null);

    useEffect(() => {
        if (prevMessages && messages.length > prevMessages.length) {
            scrollView.current.scrollToBottom();
        }
    }, [prevMessages, messages]);

    function onScrollTop(offset) {
        const scrolled = offset > 0;
        
        setScrolled(scrolled);
    }

    function onApproxScrollTop() {
        if (loadState && loadState.skip && loadState.status === 'idle') {
            dispatch(loadMessages({ chatId: props.chatId, skip: messages.length }));
        }
    }

    const renderedMessageGroups = messageGroups.map(group => {
        return (
            <MessageGroup key={group.id} group={group} />
        );
    });

    const classNameBuilder = new ClassNameBuilder('message-scroll-container flex-grow');
    if (scrolled) {
        classNameBuilder.addClassName('scrolled');
    }

    return (
        <div className={classNameBuilder.toString()}>
            <ScrollView ref={scrollView} autoScrollToBottom onApproxScrollTop={onApproxScrollTop} onScrollTop={onScrollTop}>
                <div className="message-container">
                    <div className="flex-grow">
                        {renderedMessageGroups}
                    </div>

                    {messages.length === 0 &&
                        <WelcomeMessage identity={props.identity} user={props.user} />
                    }

                    <ProductLink />
                </div>
            </ScrollView>

            <TypeIndicator chatId={props.chatId} />
        </div>
    );
}