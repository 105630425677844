import './ErrorPage.css';

import errorImg from '../assets/images/error.jpg';

export default function ErrorPage() {
    return (
        <div className="error-page">
            <div className="flex-container-vert center">
                <div className="text-center">
                    <div className="section">
                        <img alt="Error" className="error-img" src={errorImg} />
                    </div>
                    <h2>Uh... Error 500!</h2>
                    <p>Something went wrong at our end. Don't worry, it's not you -  it's us. Sorry about that.</p>
                </div>
            </div>
        </div>
    );
}