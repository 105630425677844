import { Spinner, SpinnerSize } from "@fluentui/react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useSearchParams } from "react-router-dom";

import { getActiveTenant } from "../modules/tenant/selectors";
import { getUser } from "../modules/user/selectors";
import { connectUser } from "../modules/user/slice";
import { validateEmailAddress } from "../services/validators";

export default function ConnectUserPage() {
    const [searchParams] = useSearchParams();

    const dispatch = useDispatch();
    const tenant = useSelector(getActiveTenant);
    const user = useSelector(getUser);
    const status = useSelector((state) => state.users.status);

    const name = searchParams.get('name');
    const email = searchParams.get('email');
    const redirectUrl = searchParams.get('redirect_url');

    const validParams = name && email && validateEmailAddress(email);

    useEffect(() => {
        if (validParams && (!user || user.email !== email)) {
            dispatch(connectUser({
                name,
                email,
                tenant: tenant.id
            }));
        }
    }, [dispatch, email, name, tenant.id, user, validParams]);

    if (user && user.email === email) {
        if (redirectUrl) {
            return (
                <Navigate to={redirectUrl} />
            );
        }
        else {
            return (
                <div className="flex-container-vert center">
                    <div className="text-center">Connected as {user.name}</div>
                </div>
            );
        }
    }
    else if (status === 'loading') {
        return (
            <div className="flex-container-vert center">
                <Spinner size={SpinnerSize.medium} label="Getting connected..." />
            </div>
        );
    }
    else {
        let label = null;

        if (!validParams) {
            label = 'Missing params. Name and email are required.';
        }
        else if (!user) {
            label = 'Error connecting user.';
        }

        return (
            <div className="flex-container-vert center text-center">{label}</div>
        );
    }
}