import chroma from "chroma-js";
import { isColorDark } from "../../services/web/style";

export function getAccentColor(branding) {
    return branding.accentColor;
}

export function getTextAccentColor(branding) {
    return isColorDark(branding.accentColor) ? '#fff' : '#333';
}

export function getPrimaryBackgroundColor(branding) {
    if (branding.backgroundColor) {
        return branding.backgroundColor;
    }
    else {
        return '#99D1D1';
    }
}

export function getSecondaryBackgroundColor(branding) {
    return chroma(getPrimaryBackgroundColor(branding)).alpha(0.6).hex();
}

export function getTertiaryBackgroundColor(branding) {
    return chroma(getPrimaryBackgroundColor(branding)).alpha(0.3).hex();
}

export function setCssBrandingVariables(branding) {
    document.documentElement.style.setProperty('--accentcolor', getAccentColor(branding));
    document.documentElement.style.setProperty('--accentcolor-text', getTextAccentColor(branding));

    document.documentElement.style.setProperty('--primary-backgroundcolor', getPrimaryBackgroundColor(branding));
    document.documentElement.style.setProperty('--secondary-backgroundcolor', getSecondaryBackgroundColor(branding));
    document.documentElement.style.setProperty('--tertiary-backgroundcolor', getTertiaryBackgroundColor(branding));
}