import { RouterProvider } from 'react-router-dom';
import { ClassNameBuilder } from './components/core';

import { createRouter } from './router';

import './App.css';

export default function App({ basename }) {
  const router = createRouter(basename);

  const classNameBuilder = new ClassNameBuilder("app");
  classNameBuilder.addClassName(basename);

  return (
    <div className={classNameBuilder}>
      <RouterProvider router={router} />
    </div>
  );
}