import { Icon } from "@fluentui/react";
import { getFileTypeIconProps } from '@fluentui/react-file-type-icons';
import { useMemo } from "react";

export default function FileTypeIcon(props) {
    const extension = useMemo(() => {
        const index = props.filename.lastIndexOf('.');

        if (index > -1 && index !== props.filename.length - 1) {
            return props.filename.substr(index + 1);
        }
        else {
            return null;
        }
    }, [props.filename]);

    return (
        <Icon {...getFileTypeIconProps({ extension: extension, size: props.size })} />
    );
}