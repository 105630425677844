export function openChatInNewWindow(url) {
    const height = 720;
    const width = 520;

    const left = (window.screen.width - width - 50);
    const top = (window.screen.height - height) / 2;

    const feature = `popup,width=${width},height=${height},left=${left},top=${top}`;

    window.open(url, 'YABLO_MESSENGER', feature);
}