import axios from 'axios';
import { getSession } from '../modules/session/selectors';

class ApiService {
    constructor(appContext) {
        this.delete = this.delete.bind(this);
        this.get = this.get.bind(this);
        this.post = this.post.bind(this);
        this.postFile = this.postFile.bind(this);
        this.postFiles = this.postFiles.bind(this);

        const state = appContext.getState();
        const clientConnectionId = state.subscriptions.connectionId;
        const session = getSession(state);

        this.api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
        });

        //this.api.defaults.headers.common['Accept'] = 'application/json';
        this.api.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
        this.api.defaults.headers.common['Content-Type'] = 'application/json';

        if (clientConnectionId) {
            this.api.defaults.headers.common['ClientConnectionId'] = clientConnectionId;
        }

        if (session && session.userToken) {
            this.api.defaults.headers.common['Authorization'] = `Bearer ${session.userToken}`;
        }
    }

    delete(url, config = null) {
        return this.api.get(url, config);
    }

    get(url, config = null) {
        return this.api.get(url, config);
    }

    post(url, data = null, config = null) {
        return this.api.post(url, data, config);
    }

    postFile(url, file, data = null, config = null) {
        const formData = new FormData();
        formData.append('file', file);

        if (data) {
            formData.append('model', JSON.stringify(data));
        }

        return this.api.post(url, formData, {
            ...config,
        });
    }

    postFiles(url, files, data = null, config = null) {
        const formData = new FormData();

        Array.from(files).forEach((file) => {
            formData.append('files', file);
        });

        if (data) {
            formData.append('model', JSON.stringify(data));
        }

        return this.api.post(url, formData, {
            ...config,
        });
    }
}

export default ApiService;