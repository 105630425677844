import { Outlet } from "react-router-dom";

import './ModalLayout.css';

export default function ModalLayout(props) {
    return (
        <div className="modal-layout-container">
            <div className="modal-layout">
                <Outlet />
            </div>
        </div>
    );
}