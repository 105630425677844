import { createSelector } from '@reduxjs/toolkit'

// selectors
const tenantEntities = (state) => state.tenants.entities;

export const getAllTenants = createSelector(tenantEntities, (entities) =>
    Object.values(entities)
)

export const getActiveTenant = (state) => {
    if (state.tenants.selectedTenantId) {
        return tenantEntities(state)[state.tenants.selectedTenantId];
    }
    else {
        return null;
    }
}

export const getStatusByTenant = (state, tenantId) => {
    return state.tenants.statusByTenant[tenantId];
}

export const getTenantById = (state, tenantId) => {
    return tenantEntities(state)[tenantId];
}