import Avatar from "./Avatar";

import './Avatar.css';

export default function AvatarStack(props) {
    const { hidePresence, identities, size } = props;

    const renderedAvatars = identities.map((identity, index) => {
        const key = `avatar-${index}`;

        return (
            <Avatar key={key} hidePresence={hidePresence} identityId={identity.id} name={identity.name} size={size} />
        );
    });

    return (
        <div className="avatar-stack">
            {renderedAvatars}
        </div>
    );
}