import unauthorized from '../assets/images/unauthorized-256.png';

export default function UnauthorizedPage() {
    return (
        <div className="bg-default flex-container-vert center">
            <div className="text-center">
                <img alt="User or team not found" className="img-96 mb-12" src={unauthorized} />
                <div>You don't have access to this chat</div>
            </div>
        </div>
    );
}