import FileSizeLabel from "./FileSizeLabel";
import FileTypeIcon from "./FileTypeIcon";

export default function FileAttachment(props) {
    const { attachment, onDownload } = props;

    return (
        <div className="attachment" onClick={onDownload}>
            <div className="icon mr-12">
                <FileTypeIcon filename={attachment.name} size={32} />
            </div>
            <div className="details">
                <div className="name no-wrap">{attachment.name}</div>
                <div className="size text-muted"><FileSizeLabel bytes={attachment.size} /></div>
            </div>
        </div>
    );
}