import ApiService from "./ApiService";

class IdentityService {
    constructor(appContext) {
        this.loadIdentityByToken = this.loadIdentityByToken.bind(this);

        this.api = new ApiService(appContext);
    }

    async loadIdentityByToken(request) {
        const { token, tenantId } = request;
        const response = await this.api.get(`/api/messenger/${tenantId}/channel/${token}`);

        return response.data;
    }
}

export default IdentityService;