import { DateTime, Interval } from "luxon";
import { getWeekday } from "../../services/Utils";

export function getTimeZone(identity) {
    const selectedIdentity = identity.members && identity.members.length > 0 ? identity.members.find(m => m.profile.timeZone) : identity;

    if (selectedIdentity && selectedIdentity.profile) {
        return selectedIdentity.profile.timeZone;
    }
    else {
        return null;
    }
}

export function isBusinessOpen(identity) {
    if (identity.profile && identity.profile.businessHours) {
        const businessHour = identity.profile.businessHours.find(h => h.day === getWeekday());
        const timeZoneBusiness = getTimeZone(identity);

        if (businessHour) {
            const startTime = DateTime.fromFormat(businessHour.startTime, 'HH:mm', { zone: timeZoneBusiness }).toLocal();
            const endTime = DateTime.fromFormat(businessHour.endTime, 'HH:mm', { zone: timeZoneBusiness }).toLocal();

            const interval = Interval.fromDateTimes(startTime, endTime);

            return interval.contains(DateTime.local());
        }
        else {
            return false;
        }
    }

    return true;
}