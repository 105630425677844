import { Spinner } from "@fluentui/react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useParams, useSearchParams } from "react-router-dom";
import { getActiveTenant } from "../modules/tenant/selectors";
import { joinUser } from "../modules/user/slice";

import failedImage from '../assets/images/chat-warning-256.png';

export default function JoinConversationPage() {
    const { chatId } = useParams();
    
    const [searchParams] = useSearchParams();
    const token = searchParams.get('token');

    const dispatch = useDispatch();

    const tenant = useSelector(getActiveTenant);
    const [status, setStatus] = useState('loading');

    useEffect(() => {
        async function join() {
            const result = await dispatch(joinUser({ chatId, token, tenant: tenant.id })).unwrap();

            if (result) {
                setStatus('joined');
            }
            else {
                setStatus('failed');
            }
        }

        if (tenant) {
            join();
        }
    }, [dispatch, chatId, token, tenant]);

    if (status === 'loading') {
        return (
            <div className="bg-default flex-container-vert center">
                <Spinner label="Joining chat..." />
            </div>
        );
    }
    else if (status === 'joined') {
        const url = `/${tenant.id}/conversations/${chatId}`;

        return (
            <Navigate to={url} />
        );
    }
    else {
        return (
            <div className="bg-default flex-container-vert center">
                <div className="text-center">
                    <img alt="Failed to join chat" className="img-48" src={failedImage} />
                    <h3>Failed to join chat</h3>
                    <p>Please make sure you used a valid link to join this chat.</p>
                </div>
            </div>
        );
    }
}