import { AnimationStyles, PersonaSize } from '@fluentui/react';
import { useSelector } from 'react-redux';
import { resolveVariables } from '../../../modules/chat/extensions';
import { isBusinessOpen } from '../../../modules/identity/extensions';
import { getPresenceById } from '../../../modules/presence/selectors';
import { AvatarStack } from '../../avatar';
import { ReplyTime } from '../../identity';

import './WelcomeMessage.css';

export default function WelcomeMessage(props) {
    const { identity, user } = props;

    const presence = useSelector(state => getPresenceById(state, identity.id));

    if (identity && identity.profile && user) {
        const greeting = resolveVariables(identity.profile.greeting, { user });

        if (identity.type === 'user' && presence) {
            const introduction = presence.status === 'offline' ? identity.profile.introductionOffline : identity.profile.introductionOnline;

            return (
                <div className="welcome-message" style={AnimationStyles.slideUpIn20}>
                    <div className="greeting">{greeting}</div>
                    <div className="introduction">{introduction}</div>

                    {presence.status !== 'offline' && 
                        <div className="reply-time">
                            <ReplyTime profile={identity.profile} replyTime={identity.profile.typicalReplyTime} />
                        </div>
                    }
                </div>
            );
        }
        else if (identity.type === 'organization') {
            const isOpen = identity.onlineStatus ? identity.onlineStatus === 'online' : isBusinessOpen(identity);
            const introduction = isOpen ? identity.profile.introductionOnline : identity.profile.introductionOffline;

            return (
                <div className="welcome-message" style={AnimationStyles.slideUpIn20}>
                    {identity.members &&
                        <div className="members">
                            <AvatarStack hidePresence identities={identity.members} size={PersonaSize.size40} />
                        </div>
                    }

                    <div className="greeting">{greeting}</div>
                    <div className="introduction">{introduction}</div>

                    {isOpen && 
                        <div className="reply-time">
                            <ReplyTime profile={identity.profile} replyTime={identity.profile.typicalReplyTime} />
                        </div>
                    }
                </div>
            );
        }
    }

    return null;
}