import ApiService from "./ApiService";

class UserService {
    constructor(appContext) {
        this.api = new ApiService(appContext);

        this.connectUser = this.connectUser.bind(this);
        this.joinUser = this.joinUser.bind(this);
    }

    async connectUser(request) {
        const { tenant, ...data } = request;

        const response = await this.api.post(`/api/messenger/${tenant}/connect`, data);

        return response.data;
    }

    async joinUser(request) {
        const { tenant, ...data } = request;

        const response = await this.api.post(`/api/messenger/${tenant}/join`, data);

        return response.data;
    }
}

export default UserService;