import OnlineProfile from "./OnlineProfile";

export default function OnlineProfiles(props) {
    const { onlineProfiles } = props;

    if (onlineProfiles.length > 0) {
        const sortedProfiles = [...onlineProfiles].sort((a, b) => a.type.localeCompare(b.type));

        return (
            <div className="online-profiles">
                {sortedProfiles.map(p => <OnlineProfile key={p.type} profile={p} />)}
            </div>
        );
    }
    else {
        return null;
    }
}