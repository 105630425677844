import verifiedBage from '../../../assets/images/verified-badge.png';
import { Avatar } from '../../avatar';

export default function ConversationHeaderUser(props) {
    const { identity } = props;

    return (
        <>
            <div className="visible-sm">
                <div className="flex-container-horz">
                    <div className="mr-12">
                        <Avatar identityId={identity.id} name={identity.name} />
                    </div>
                    <div>
                        <div className="name">{identity.name}</div>
                        <div className="description">{identity.profile.organization}</div>
                    </div>
                </div>
            </div>

            <div className="verified hidden-sm">
                <img alt="Verified business account" className="badge" src={verifiedBage} />
                <div>Verified {identity.profile.organization} business account</div>
            </div>
        </>
    );
}