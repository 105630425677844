import { Spinner, SpinnerSize } from "@fluentui/react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getIdentityByToken, getStatusByToken } from "../../../modules/identity/selectors";
import { loadIdentityByToken } from "../../../modules/identity/slice";
import { getActiveTenant } from "../../../modules/tenant/selectors";

import Conversation from "../conversation/Conversation";

import notFound from '../../../assets/images/business-group-256.png';

export default function ChatSession(props) {
    const { chatId, token } = props;

    const identity = useSelector(state => getIdentityByToken(state, token));
    const loadState = useSelector(state => getStatusByToken(state, token));
    const tenant = useSelector(getActiveTenant);

    const dispatch = useDispatch();

    useEffect(() => {
        if (token && !identity) {
            dispatch(loadIdentityByToken({ tenantId: tenant.id, token }));
        }
    }, [dispatch, identity, tenant.id, token]);

    if (chatId) {
        return (
            <Conversation chatId={chatId} />
        );
    }
    else {
        if (loadState && loadState.status === 'loading') {
            return (
                <div className="bg-default flex-container-vert center">
                    <Spinner size={SpinnerSize.medium} label="Loading chat..." />
                </div>
            );
        }
        else if (identity) {
            return (
                <Conversation identity={identity} token={token} />
            );
        }
        else {
            return (
                <div className="bg-default flex-container-vert center">
                    <div className="text-center">
                        <img alt="User or team not found" className="img-96 mb-12" src={notFound} />
                        <div>User or team could not be found.</div>
                    </div>
                </div>
            );
        }
    }
}