import { Spinner, SpinnerSize } from '@fluentui/react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getChatById, getStatusByChat } from '../../../modules/chat/selectors';
import { loadChat, subscribeToChat } from '../../../modules/chat/slice';
import { createId } from '../../../services/Utils';

import { ConversationHeader, ConversationProfile, MessageComposer, MessageContainer, UserForm } from '../../chat';

import { getUser } from '../../../modules/user/selectors';
import { getActiveTenant } from '../../../modules/tenant/selectors';
import { useAppContext } from '../../../AppContext';
import { getSecondaryBackgroundColor } from '../../../modules/tenant/extensions';

import chatNotFound from '../../../assets/images/chat-warning-256.png';
import './Conversation.css'

export default function Conversation(props) {
    const chatId = props.chatId ? props.chatId : createId();

    const chat = useSelector(state => getChatById(state, chatId));
    const loadState = useSelector(state => getStatusByChat(state, chatId));
    const tenant = useSelector(getActiveTenant);
    const user = useSelector(getUser);

    const identity = chat ? chat.channel : props.identity;

    const context = useAppContext();
    const dispatch = useDispatch();

    useEffect(() => {
        if (props.chatId && !chat) {
            dispatch(loadChat({ chatId: props.chatId }));
        }
    }, [dispatch, props.chatId, chat]);

    useEffect(() => {
        if (props.chatId) {
            dispatch(subscribeToChat({ chatId: props.chatId }));
        }
    }, [dispatch, props.chatId]);

    if (loadState && loadState.status === 'loading') {
        return (
            <div className="bg-default flex-container-vert center">
                <Spinner size={SpinnerSize.medium} label="Loading chat..." />
            </div>
        );
    }
    else if (chat || !props.chatId) {
        const style = {};

        if (context.runsEmbedded()) {
            style.backgroundColor = getSecondaryBackgroundColor(tenant.branding);
        }

        return (
            <div className="conversation-container" style={style}>
                <div className="conversation flex-container-horz">
                    <ConversationProfile chat={chat} identity={props.identity} tenant={tenant} />

                    <div className="chat flex-container-vert flex-grow">
                        <ConversationHeader identity={identity} tenant={tenant} />

                        {!user &&
                            <div className="flex-container-vert center">
                                <UserForm identity={identity} />
                            </div>
                        }

                        <MessageContainer chatId={chatId} identity={identity} user={user} />

                        {user && <MessageComposer chatId={chatId} recipient={props.identity} tenant={tenant} token={props.token} />}
                    </div>
                </div>
            </div>
        );
    }
    else {
        return (
            <div className="bg-default flex-container-vert center text-center">
                <div>
                    <img alt="Chat not found" className="img-96" src={chatNotFound} />
                    <div>Chat could not be found.</div>
                </div>
            </div>
        );
    }
}