import { Outlet } from "react-router-dom";

import './RootLayoutEmbed.css';

export default function RootLayoutEmbed() {
    return (
        <div className="root-layout root-layout-embed">
            <Outlet />
        </div>
    );
}