import { previewChanged, visibilityChanged } from "./modules/host/slice";

class Host {
    constructor(appContext) {
        this.appContext = appContext;

        this.getTarget = this.getTarget.bind(this);
        this.handleEvent = this.handleEvent.bind(this);
        this.postEvent = this.postEvent.bind(this);

        this._addListener = this._addListener.bind(this);

        this._addListener();
    }

    handleEvent(ev) {
        switch (ev.type) {
            case 'PREVIEW_CHANGED':
                this.appContext.dispatch(previewChanged(ev.data));
                break;

            case 'VISIBILITY_CHANGED':
                this.appContext.dispatch(visibilityChanged(ev.data.visibility));
                break;

            default:
        }
    }

    postEvent(ev) {
        const target = this.getTarget();

        if (target) {
            target.postMessage(ev, '*');
        }
    }

    getTarget() {
        if (window.parent && window.parent !== window) {
            return window.parent;
        }
        else {
            return null;
        }
    }

    _addListener() {
        const target = this.getTarget();

        if (target != null) {
            window.addEventListener('message', (ev) => {
                this.handleEvent(ev.data);
            });
        }
    }
}

export default Host;