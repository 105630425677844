import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { appContext } from "../../AppContext";
import ClientConnectionService from "../../services/ClientConnectionService";

// initial state
const initialState = {
    connectionId: null,
    subscriptions: [],
    events: []
};

// thunks
export const addEvent = createAsyncThunk('subscriptions/addEvent', async (request, thunkAPI) => {
    thunkAPI.dispatch(slice.actions.eventAdded(request));

    if (request.expiresAfterSeconds) {
        setTimeout(() => {
            thunkAPI.dispatch(slice.actions.eventRemoved(request));
        }, request.expiresAfterSeconds * 1000);
    }
});

export const subscribe = createAsyncThunk('subscriptions/subscribe', async (request) => {
    const clientConnectionService = new ClientConnectionService(appContext());
    return await clientConnectionService.subscribe(request);
});

export const unsubscribe = createAsyncThunk('subscriptions/unsubscribe', async (request) => {
    const clientConnectionService = new ClientConnectionService(appContext());
    return await clientConnectionService.unsubscribe(request);
});

// slice
const slice = createSlice({
    name: 'subscriptions',
    initialState,
    reducers: {
        clientConnected(state, action) {
            state.connectionId = action.payload.connectionId;
        },
        clientDisconnected(state, action) {
            state.connectionId = null;
        },
        eventAdded(state, action) {
            state.events.push(action.payload);
        },
        eventRemoved(state, action) {
            state.events = state.events.filter(ev => ev.id !== action.payload.id);
        }
    },
    extraReducers(builder) {
        builder
            .addCase(subscribe.fulfilled, (state, action) => {
                action.payload.forEach(subscription => {
                    state.subscriptions.push(subscription);
                });
            })
            .addCase(unsubscribe.fulfilled, (state, action) => {
                state.subscriptions = state.subscriptions.filter(v => action.payload.indexOf(v) === -1);
            });
    }
});

// actions
export const { clientConnected, clientDisconnected, eventAdded, eventRemoved } = slice.actions;

// reducer
export default slice.reducer;