import { DateTime } from "luxon";
import { plural } from "../../services/Utils";
import { DateTimeLabel } from "../core";

export default function TimeZone(props) {
    const { timeZone } = props;

    const getProfileTime = () => {
        return DateTime.local().setZone(timeZone);
    }

    const localTime = DateTime.local();
    const profileTime = getProfileTime();

    const diff = (profileTime.offset - localTime.offset) / 60;

    let text = '';
    if (diff === 0) {
        text = 'Same time as you';
    }
    else if (diff < 0) {
        text = `${plural(Math.abs(diff), 'hour')} earlier`;
    }
    else {
        text = `${plural(diff, 'hour')} later`;
    }

    return (
        <span><DateTimeLabel toFormat="t" value={getProfileTime} /> - {text}</span>
    );
}