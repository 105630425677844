import { forwardRef, useEffect } from "react";
import { Scrollbars } from "react-custom-scrollbars-2";

const SCROLL_TRESHOLD = 100;

const ScrollView = forwardRef((props, ref) => {
    useEffect(() => {
        if (props.autoScrollToBottom) {
            ref.current.scrollToBottom();
        }
    }, [props.autoScrollToBottom, ref]);

    function onScrollFrame(values) {
        const offsetTop = Math.max(values.scrollTop, 0);

        if (props.onScrollTop) {
            props.onScrollTop(offsetTop);
        }

        if (props.onApproxScrollTop && offsetTop < SCROLL_TRESHOLD) {
            props.onApproxScrollTop(offsetTop);
        }
    }

    return (
        <Scrollbars ref={ref} autoHide onScrollFrame={onScrollFrame}>
            {props.children}
        </Scrollbars>
    );
});

export default ScrollView;