class NotificationService {
    constructor(appContext) {
        this.playSound = this.playSound.bind(this);
    }

    playSound(type) {
        let filename = null;

        switch (type) {
            case 'inbound':
                filename = 'inbound.mp3';
                break;

            case 'outbound':
                filename = 'outbound.mp3';
                break;

            default:
        }

        if (filename) {
            try {
                const src = process.env.PUBLIC_URL + `/sounds/${filename}`;
                const audio = new Audio(src);
                audio.play();
            }
            catch (ex) { }
        }
    }
}

export default NotificationService;