import defaultLogo from '../../assets/images/placeholder-logo.png';

import "./BusinessLogo.css";

export default function BusinessLogo(props) {
    const { tenant } = props;

    if (tenant) {
        const logo = tenant.branding.logo ? `data:image/png;base64,${tenant.branding.logo.data}` : defaultLogo;

        return (
            <div className="business-logo">
                <img alt="Business logo" src={logo} />
            </div>
        );
    }
    else {
        return null;
    }
}