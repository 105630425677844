export function getContentDimension(file) {
    return new Promise(resolve => {
        const dataURL = URL.createObjectURL(file);

        const img = new Image();

        img.onload = () => {
            resolve({
                height: img.height,
                width: img.width
            });

            URL.revokeObjectURL(dataURL);
        }
        
        img.src = dataURL
    });
}