import ApiService from "./ApiService";

class TenantService {
    constructor(appContext) {
        this.api = new ApiService(appContext);

        this.loadTenant = this.loadTenant.bind(this);
    }

    async loadTenant(tenantId, origin) {
        if (origin) {
            const response = await this.api.post(`/api/messenger/${tenantId}/register_messenger`, { handle: origin, type: 'web' });
            return response.data;
        }
        else {
            const response = await this.api.get(`/api/messenger/${tenantId}`);
            return response.data;
        }
    }
}

export default TenantService;