class ClassNameBuilder {
    constructor(className = null) {
        this.addClassName = this.addClassName.bind(this);
        this.toString = this.toString.bind(this);

        this.values = className ? [className] : [];
    }

    addClassName(className) {
        if (className) {
            this.values.push(className);
        }
    }

    toString() {
        return this.values.join(' ');
    }
}

export default ClassNameBuilder;