import { createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { appContext } from "../../AppContext";
import FileService from "../../services/FileService";

// adapter
const filesAdapter = createEntityAdapter();

// initial state
const initialState = filesAdapter.getInitialState({
    statusByFile: {}
});

// thunks
export const loadFilePreview = createAsyncThunk('files/loadFilePreview', async (request) => {
    const fileService = new FileService(appContext());
    const blob = await fileService.loadFilePreview(request);

    if (blob) {
        return URL.createObjectURL(blob);
    }
    else {
        return null;
    }
});

export const uploadFile = createAsyncThunk('files/uploadFile', async (request) => {
    const { file, fileId } = request;

    const fileService = new FileService(appContext());
    return await fileService.uploadFile({ fileId }, file);
});

// slice
const slice = createSlice({
    name: 'files',
    initialState,
    reducers: {
        fileRemoved(state, action) {
            const file = filesAdapter.getSelectors().selectById(state, action.payload.fileId);

            if (file) {
                if (file.preview) {
                    URL.revokeObjectURL(file.preview);
                }

                filesAdapter.removeOne(state, action.payload.fileId);
            }
        }
    },
    extraReducers(builder) {
        builder
            .addCase(loadFilePreview.pending, (state, action) => {
                state.statusByFile[action.meta.arg.fileId] = { status: 'loading' };
            })
            .addCase(loadFilePreview.fulfilled, (state, action) => {
                if (action.payload) {
                    const file = { 
                        id: action.meta.arg.fileId,
                        preview: action.payload
                    };

                    filesAdapter.upsertOne(state, file);
                }

                state.statusByFile[action.meta.arg.fileId] = { status: 'idle' };
            })
            .addCase(uploadFile.pending, (state, action) => {
                state.statusByFile[action.meta.arg.fileId] = { status: 'loading' };
            })
            .addCase(uploadFile.fulfilled, (state, action) => {
                if (action.payload) {
                    const file = { ...action.payload };

                    if (file.contentType.indexOf('image/') === 0) {
                        file.preview = URL.createObjectURL(action.meta.arg.file);
                    }

                    filesAdapter.upsertOne(state, file);
                }

                state.statusByFile[action.meta.arg.fileId] = { status: 'idle' };
            })
        }
});

// actions
export const { fileAdded, fileRemoved } = slice.actions;

// reducer
export default slice.reducer;