import { createContext, useContext } from "react";
import Host from "./Host";
import { getSession } from "./modules/session/selectors";

import { clientConnected, clientDisconnected } from "./modules/subscription/slice";
import ClientConnectionService, { createClientConnection } from "./services/ClientConnectionService";
import { getUrlParameter } from "./services/web/utils";

let _appContextInstance = null;

class AppContext {
    constructor(store) {
        this.dispatch = this.dispatch.bind(this);
        this.getState = this.getState.bind(this);
        this.invokeClientConnection = this.invokeClientConnection.bind(this);
        this.onClientConnected = this.onClientConnected.bind(this);
        this.onClientDisconnected = this.onClientDisconnected.bind(this);
        this.onClientMessageReceived = this.onClientMessageReceived.bind(this);
        this.runsEmbedded = this.runsEmbedded.bind(this);

        this.basename = getBasename();
        this.clientConnection = null;
        this.clientRequestsQueue = [];
        this.host = new Host(this);
        this.pingIntervalId = null;
        this.store = store;
    }

    dispatch(action) {
        this.store.dispatch(action);
    }

    getState() {
        return this.store.getState();
    }

    invokeClientConnection(methodName, ...args) {
        if (this.clientConnection && this.clientConnection.connection.connectionState === 1) {
            this.clientConnection.invoke(methodName, ...args);
        }
        else {
            this.clientRequestsQueue.push({ methodName, args });

            if (!this.clientConnection) {
                const state = this.getState();
                const session = getSession(state);

                if (session && session.userToken) {
                    this.clientConnection = createClientConnection({ onConnected: this.onClientConnected, onDisconnected: this.onClientDisconnected, onMessageReceived: this.onClientMessageReceived, token: session.userToken });
                }
            }
        }
    }

    onClientConnected() {
        this.clientRequestsQueue.forEach(r => {
            this.clientConnection.invoke(r.methodName, ...r.args);
        });

        this.dispatch(clientConnected({ connectionId: getConnectionId(this.clientConnection) }));

        this.clientRequestsQueue = [];

        if (!this.pingIntervalId) {
            this.pingIntervalId = setInterval(() => this.invokeClientConnection('ping'), 5 * 60 * 1000);
        }
    }

    onClientDisconnected() {
        this.dispatch(clientDisconnected());

        if (this.pingIntervalId) {
            clearInterval(this.pingIntervalId);
            this.pingIntervalId = null;
        }
    }

    onClientMessageReceived(type, data) {
        const clientConnectionService = new ClientConnectionService(this);
        clientConnectionService.onMessageReceived(type, data);
    }

    runsEmbedded() {
        return this.basename === 'embed';
    }
}

function getBasename() {
    const pathname = window.location.pathname;

    if (pathname.indexOf('/embed/') === 0) {
        return 'embed';
    }
    else {
        return null;
    }
}

function getConnectionId(clientConnection) {
    try {
        const url = clientConnection.connection.transport.webSocket.url;

        return getUrlParameter(url, 'id');
    }
    catch (ex) {
        return null;
    }
}

export function appContext() {
    return _appContextInstance;
}

export function createAppContext(store) {
    _appContextInstance = new AppContext(store);

    return _appContextInstance;
}

// React Context
const ReactAppContext = createContext(undefined);

export const AppContextProvider = ReactAppContext.Provider;

export function useAppContext() {
    return useContext(ReactAppContext);
}