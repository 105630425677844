import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { appContext } from '../../AppContext';
import HostService from '../../services/HostService';
import MessageService from '../../services/MessageService';
import NotificationService from '../../services/NotificationService';
import { addIdentities } from '../identity/slice';
import { getUser } from '../user/selectors';

import * as extensions from './extensions';
import { getMessageById } from './selectors';

// adapter
const messagesAdapter = createEntityAdapter();

// initial state
const initialState = messagesAdapter.getInitialState({
    statusByChat: {}
});

// thunks
export const createMessage = createAsyncThunk('messages/createMessage', async (request, thunkAPI) => {
    const user = getUser(appContext().getState());

    const message = extensions.createMessage({ ...request, from: { id: user.id, name: user.name }});
    thunkAPI.dispatch(messageAdded(message));

    const notificationService = new NotificationService(appContext());
    notificationService.playSound('outbound');

    const messageService = new MessageService(appContext());
    return await messageService.createMessage({ ...request, id: message.id });
});

export const loadMessage = createAsyncThunk('messages/loadMessage', async (request, thunkAPI) => {
    const isExistingMessage = getMessageById(appContext().getState(), request.id);
    const user = getUser(appContext().getState());

    const messageService = new MessageService(appContext());
    const message = await messageService.loadMessage(request.id);

    if (message) {
        const isInboundMessage = message.from && message.from.id !== user.id;

        if (isInboundMessage && !isExistingMessage) {
            const notificationService = new NotificationService(appContext());
            notificationService.playSound('inbound');

            thunkAPI.dispatch(addIdentities([message.from]));

            const hostService = new HostService(appContext());
            hostService.notifyInboundMessage(message);
        }

        if (!message.isReadByMe) {
            messageService.markMessageAsRead(message.id);
        }
    }

    return message;
});

export const loadMessages = createAsyncThunk('messages/loadMessages', async (request) => {
    const messageService = new MessageService(appContext());
    return await messageService.loadMessagesByChatId(request.chatId, request.skip);
});

// slice
const slice = createSlice({
    name: 'messages',
    initialState,
    reducers: {
        messageAdded(state, action) {
            messagesAdapter.addOne(state, action.payload);
        }
    },
    extraReducers(builder) {
        builder
            .addCase(createMessage.fulfilled, (state, action) => {
                if (action.payload) {
                    messagesAdapter.upsertOne(state, action.payload);
                }
            })
            .addCase(loadMessage.fulfilled, (state, action) => {
                if (action.payload) {
                    messagesAdapter.upsertOne(state, action.payload);
                }
            })
            .addCase(loadMessages.pending, (state, action) => {
                state.statusByChat[action.meta.arg.chatId] = { status: 'loading' };
            })
            .addCase(loadMessages.fulfilled, (state, action) => {
                messagesAdapter.upsertMany(state, action.payload.values);
                state.statusByChat[action.meta.arg.chatId] = { skip: action.payload.skip, status: 'idle' };
            });
    }
});

// actions
export const { messageAdded } = slice.actions;

// reducer
export default slice.reducer;