import { useResolvedPath } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useAppContext } from '../../../AppContext';
import { IconButton } from '@fluentui/react';

import { dismissChat, handOffChat } from '../../../modules/host/slice';

import ConversationHeaderTeam from './ConversationHeaderTeam';
import ConversationHeaderUser from './ConversationHeaderUser';

import './ConversationHeader.css';

export default function ConversationHeader(props) {
    const { identity, readOnly, tenant } = props;

    const context = useAppContext();
    const dispatch = useDispatch();
    const path = useResolvedPath();

    const dismiss = () => {
        dispatch(dismissChat());
    }

    const openInNewWindow = () => {
        dispatch(handOffChat({ url: path.pathname }));
    }

    if (identity) {
        const icon = tenant.branding.icon ? `data:image/png;base64,${tenant.branding.icon.data}` : null;

        return (
            <div className="conversation-header flex-container-horz">
                <div className="flex-grow">
                    {identity.profile.$type === 'teamProfile' && <ConversationHeaderTeam identity={identity} />}
                    {identity.profile.$type === 'userProfile' && <ConversationHeaderUser identity={identity} />}
                </div>

                <div className="ml-12">
                    {context.runsEmbedded() && !readOnly &&
                        <IconButton iconProps={{ iconName: 'OpenInNewTab' }} onClick={openInNewWindow} title="Open in new window" />
                    }

                    {context.runsEmbedded() && !readOnly && 
                        <IconButton iconProps={{ iconName: 'Cancel' }} onClick={dismiss} title="Close messenger" />
                    }

                    {!context.runsEmbedded() && icon &&
                        <div className="logo-container visible-sm">
                            <img className="logo" alt={tenant.name} src={icon} title={tenant.name} />
                        </div>
                    }
                </div>
            </div>
        );
    }
    else {
        return null;
    }
}