//import { createSelector } from '@reduxjs/toolkit'

const chatEntities = (state) => state.chats.entities;

// const getAllChats = createSelector(chatEntities, (entities) =>
//     Object.values(entities)
// )

export const getChatById = (state, chatId) => {
    return chatEntities(state)[chatId];
}

export const getStatusByChat = (state, chatId) => {
    return state.chats.statusByChat[chatId];
}