import ApiService from "./ApiService";
import ClientConnectionService from "./ClientConnectionService";

class ChatService {
    constructor(appContext) {
        this.api = new ApiService(appContext);
        this.clientConnection = new ClientConnectionService(appContext);

        this.createChat = this.createChat.bind(this);
        this.loadChat = this.loadChat.bind(this);
        this.markAsRead = this.markAsRead.bind(this);
        this.sendUserIsWriting = this.sendUserIsWriting.bind(this);
    }

    async createChat(request) {
        const { id, attachments, body, messageId, token } = request;

        const response = await this.api.post('/api/messenger/chats', { id, attachments, body, messageId, token });

        return response.data;
    }

    async loadChat(chatId) {
        const response = await this.api.get(`/api/messenger/chats/${chatId}`);

        return response.data;
    }

    async markAsRead(chatId) {
        await this.api.post(`/api/messenger/chats/${chatId}/read`);
    }

    sendUserIsWriting(chatId) {
        this.clientConnection.sendEvent({
            entity: chatId,
            scope: 'unrestricted',
            type: 'userIsWriting'
        });
    }
}

export default ChatService;