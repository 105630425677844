import { Icon, PersonaSize } from "@fluentui/react";

import { Avatar } from "../avatar";
import BusinessHours from "./BusinessHours";
import BusinessLogo from "./BusinessLogo";
import JobTitle from "./JobTitle";
import TimeZone from "./TimeZone";
import Location from "./Location";
import OnlineProfiles from "./OnlineProfiles";

import { getTimeZone } from "../../modules/identity/extensions";

import './Profile.css';

export default function Profile(props) {
    const { identity, tenant } = props;
    const { profile } = identity;

    const renderAvatar = profile.$type === 'botProfile' || profile.$type === 'userProfile';
    const renderLogo = profile.$type === 'teamProfile';

    const timeZone = getTimeZone(identity);

    if (profile) {
        return (
            <div className="profile">
                <div className="body">
                    <div className="flex-container-horz center">
                        {renderAvatar && <Avatar avatar={profile.avatar} identityId={identity.id} name={identity.name} size={PersonaSize.size72} />}
                        {renderLogo && <BusinessLogo tenant={tenant} />}
                    </div>

                    <div className="text-center">
                        {!renderLogo && <div className="name">{identity.name}</div>}
                        <JobTitle profile={profile} />
                    </div>

                    {profile.onlineProfiles.length > 0 &&
                        <div className="section section-sm">
                            <OnlineProfiles onlineProfiles={profile.onlineProfiles} />
                        </div>
                    }

                    {profile.biography && profile.biography.trim().length > 0 &&
                        <div className="section">
                            <div className="biography">{profile.biography}</div>
                        </div>
                    }
                </div>

                <div className="footer">
                    {profile.calendarLink &&
                        <div className="profile-prop flex-container-horz">
                            <Icon className="mr-12" iconName="Event" />
                            <div><a className="profile-link" href={profile.calendarLink} rel="noreferrer" target="_blank">Schedule a meeting</a></div>
                        </div>
                    }
                    {profile.businessHours &&
                        <div className="profile-prop flex-container-horz">
                            <Icon className="mr-12" iconName="BusinessHoursSign" title="Business hours" />
                            <BusinessHours businessHours={profile.businessHours} timeZone={timeZone} />
                        </div>
                    }
                    {profile.location && (profile.location.city || profile.location.country) &&
                        <div className="profile-prop flex-container-horz">
                            <Icon className="mr-12" iconName="MapPin" title="Location" />
                            <div><Location location={profile.location} /></div>
                        </div>
                    }
                    <div className="profile-prop flex-container-horz">
                        <Icon className="mr-12" iconName="Clock" title="Current time" />
                        <div><TimeZone timeZone={timeZone} /></div>
                    </div>
                </div>
            </div>
        );
    }
    else {
        return (
            <div className="section text-center">No profile found</div>
        );
    }
}