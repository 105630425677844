import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { FLUSH, PAUSE, PERSIST, persistReducer, persistStore, PURGE, REGISTER, REHYDRATE } from 'redux-persist';
import sessionStorage from 'redux-persist/es/storage/session';
import storage from 'redux-persist/lib/storage';

import chatReducer from './modules/chat/slice';
import fileReducer from './modules/file/slice';
import hostReducer from './modules/host/slice';
import identityReducer from './modules/identity/slice';
import messageReducer from './modules/message/slice';
import presenceReducer from './modules/presence/slice';
import sessionReducer from './modules/session/slice';
import subscriptionReducer from './modules/subscription/slice';
import tenantReducer from './modules/tenant/slice';
import userReducer from './modules/user/slice';

const rootConfig = {
    key: 'root',
    storage,
    whitelist: ['tenants', 'users']
};

const sessionConfig = {
  key: 'sessions',
  storage: sessionStorage
};

const rootReducer = combineReducers({
    chats: chatReducer,
    files: fileReducer,
    host: hostReducer,
    identities: identityReducer,
    messages: messageReducer,
    presence: presenceReducer,
    sessions: persistReducer(sessionConfig, sessionReducer),
    subscriptions: subscriptionReducer,
    tenants: tenantReducer,
    users: userReducer
});

const persistedReducer = persistReducer(rootConfig, rootReducer)

const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      }
    })
});

const persistor = persistStore(store);

export { persistor, store};