import { useSelector } from "react-redux";

import { getUser } from "../../../modules/user/selectors";
import { Avatar } from "../../avatar";

import { Message, MessageGroupFooter } from '../../chat';
import { ClassNameBuilder } from "../../core";

import './MessageGroup.css';

export default function MessageGroup(props) {
    const { group } = props;
    const lastMessage = group.items[group.items.length - 1].message;

    const user = useSelector(getUser);

    const classNameBuilder = new ClassNameBuilder('message-group');
    if (user.id === group.sender.id) {
        classNameBuilder.addClassName('inverse');
    }

    const renderedMessages = group.items.map((item, index) => {
        const { message } = item;

        return (
            <Message
                key={message.id}
                message={message}
            />
        );
    });

    return (
        <div className={classNameBuilder}>
            {user.id !== group.sender.id &&
                <Avatar identityId={group.sender.id} name={group.sender.name} />
            }
            <div className="messages">
                {renderedMessages}

                <MessageGroupFooter message={lastMessage} user={user} />
            </div>
        </div>
    );
}