import facebookLogo from '../../assets/icons/333/facebook-circled.png';
import linkedInLogo from '../../assets/icons/333/linkedin-circled.png';
import twitterLogo from '../../assets/icons/333/twitter-circled.png';
import webLogo from '../../assets/icons/333/link-circled.png';

import { removeProtocol } from '../../services/Utils';

export default function OnlineProfile(props) {
    const { profile } = props;

    const getOnlineProfileProps = () => {
        switch (profile.type) {
            case 'facebook':
                return {
                    text: 'Facebook',
                    logo: facebookLogo
                };

            case 'linkedIn':
                return {
                    text: 'LinkedIn',
                    logo: linkedInLogo
                };

            case 'twitter':
                return {
                    text: 'Twitter',
                    logo: twitterLogo
                };

            case 'website':
            default:
                return {
                    text: removeProtocol(profile.url),
                    logo: webLogo
                };
        }
    }

    const onlineProfileProps = getOnlineProfileProps();

    return (
        <a href={profile.url} rel="noreferrer" target="_blank" title={onlineProfileProps.text}>
            <img alt={onlineProfileProps.text} className="logo" src={onlineProfileProps.logo} />
        </a>
    );
}