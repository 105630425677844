
const identityEntities = (state) => state.identities.entities;

export const getIdentityById = (state, identityId) => {
    return identityEntities(state)[identityId];
}

export const getIdentityByToken = (state, token) => {
    const value = state.identities.identityByToken[token];

    if (value && value.identityId) {
        return getIdentityById(state, value.identityId);
    }
    else {
        return null;
    }
}

export const getStatusByToken = (state, token) => {
    return state.identities.identityByToken[token];
}