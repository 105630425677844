import { createBrowserRouter, createRoutesFromElements, Route } from "react-router-dom"
import { SessionBoundary, TenantBoundary, UserBoundary } from "./components/core";
import { ModalBodyLayout, ModalLayout, RootLayout, RootLayoutEmbed } from "./layouts";
import { ConversationsPage, ErrorPage, JoinConversationPage, PreviewPage, UnauthorizedPage } from "./pages";
import ConnectUserPage from "./pages/ConnectUserPage";

const createRouteElements = (RootComponent, ModalComponent) => {
    return createRoutesFromElements(
        <Route path="/" errorElement={<ErrorPage />}>
            <Route path="preview" element={<PreviewPage />} />
            <Route element={RootComponent}>
                <Route element={ModalComponent}>
                    <Route path=":tenantId" element={<TenantBoundary />}>
                        <Route element={<SessionBoundary />}>
                            <Route element={<ModalBodyLayout />}>
                                <Route path="connect" element={<ConnectUserPage />} />
                                <Route path="unauthorized" element={<UnauthorizedPage />} />
                            </Route>

                            <Route path=":token" element={<ConversationsPage />} />
                            <Route element={<UserBoundary />}>
                                <Route path="conversations/:chatId" element={<ConversationsPage />} />
                            </Route>
                            <Route path="conversations/:chatId/join" element={<JoinConversationPage />} />
                        </Route>
                    </Route>
                </Route>
            </Route>
        </Route>
    );
};

const defaultRoutes = createRouteElements(<RootLayout />, <ModalLayout />);

const embedRoutes = createRouteElements(<RootLayoutEmbed />, <ModalLayout />);

export const createRouter = (basename) => {
    if (basename) {
        switch (basename) {
            case 'embed':
                return createBrowserRouter(embedRoutes, { basename: '/embed' });

            default:
                return createBrowserRouter(defaultRoutes);
        }
    }
    else {
        return createBrowserRouter(defaultRoutes);
    }
}