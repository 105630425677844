import { DateTimeLabel } from "../../core";

import './MessageGroupFooter.css';

export default function MessageGroupFooter(props) {
    const renderReadStatus = props.user.id === props.message.from.id && props.message.isReadByAgents;
    const renderSending = props.user.id === props.message.from.id && props.message.status === 'processing';

    return (
        <div className="footer">
            <div>
                {renderSending && <div>Sending</div>}
                {!renderSending && <DateTimeLabel toRelative value={props.message.createdOn} />}
            </div>

            {renderReadStatus && <div>&nbsp;• Seen</div>}
        </div>
    );
}