import ConversationHeader from "../conversationHeader/ConversationHeader";
import ConversationProfile from "../conversationProfile/ConversationProfile";
import MessageComposer from "../messageComposer/MessageComposer";
import MessageContainer from "../messageContainer/MessageContainer";

export default function ChatPreview(props) {
    const { identity, tenant, user } = props;

    return (
        <div className="conversation-container">
            <div className="conversation flex-container-horz">
                <ConversationProfile identity={identity} tenant={tenant} />

                <div className="chat flex-container-vert flex-grow">
                    <ConversationHeader identity={identity} readOnly tenant={tenant} />

                    <MessageContainer identity={identity} user={user} />

                    <MessageComposer readOnly recipient={identity} tenant={tenant} />
                </div>
            </div>
        </div>
    );
}