import { createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { getUsers } from "../user/selectors";

// adapter
const sessionsAdapter = createEntityAdapter();

// initial state
const initialState = sessionsAdapter.getInitialState();

export const startSession = createAsyncThunk('sessions/start', (request, thunkAPI) => {
    const users = getUsers(thunkAPI.getState());

    if (users.length > 0) {
        const user = users[0];

        return {
            id: user.tenant,
            userId: user.id,
            userToken: user.tokens.length > 0 ? user.tokens[0] : null
        };
    }
    else {
        return null;
    }
});

// slice
const slice = createSlice({
    name: 'sessions',
    initialState,
    reducers: {
        sessionStarted(state, action) {
            sessionsAdapter.upsertOne(state, action.payload);
        }
    },
    extraReducers(builder) {
        builder
            .addCase(startSession.fulfilled, (state, action) => {
                if (action.payload) {
                    sessionsAdapter.upsertOne(state, action.payload);
                }
            });
    }
});

// actions
export const { sessionStarted } = slice.actions;

// reducer
export default slice.reducer;