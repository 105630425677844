import { DateTime } from 'luxon';
import { v4 as guid } from 'uuid';

export function createId() {
    return guid();
}

export function debounce(fn, delay) {
    var timer = null;
    return function () {
        var context = this, args = arguments;
        clearTimeout(timer);
        timer = setTimeout(function () {
            fn.apply(context, args);
        }, delay);
    };
}

export function getFirstName(name) {
    const trimmedName = name.trim();

    let index = trimmedName.indexOf(',');
    if (index > -1 && index < trimmedName.length - 1) {
        return trimmedName.slice(index + 1).trim();
    }
    else {
        index = trimmedName.indexOf(' ');

        if (index > -1) {
            return trimmedName.slice(0, index);
        }
        else {
            return trimmedName;
        }
    }
}

export function getLastName(name) {
    const trimmedName = name.trim();

    let index = trimmedName.indexOf(',');
    if (index > -1 && index < trimmedName.length - 1) {
        return trimmedName.slice(0, index).trim();
    }
    else {
        index = trimmedName.indexOf(' ');

        if (index > -1) {
            return trimmedName.slice(index + 1).trim();
        }
        else {
            return trimmedName;
        }
    }
}

export function getWeekday() {
    const days = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
    const weekday = DateTime.local().weekday;

    return days[weekday - 1];
}

export function plural(count, noun) {
    const postfix = (count === 1) ? noun : noun + 's';

    return `${count} ${postfix}`;
}

export function removeProtocol(url) {
    return url ? url.replace(/(^\w+:|^)\/\//, '') : null;
}